var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "mainSlide" }, [
      _c("div", { staticClass: "slideWrap" }, [
        _c("div", { staticClass: "slideText" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("product_title_1")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("product_context_1")) + " ")])
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/main_slide.png"),
            alt: "이미지"
          }
        })
      ])
    ]),
    _c("div", { staticClass: "mainSection2" }, [
      _c("div", { staticClass: "section2Wrap" }, [
        _c(
          "div",
          { staticClass: "section2Text" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("product_title_2")))]),
            _c("p", { staticClass: "section2_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("product_context_2")) + " "),
              _c("br")
            ]),
            _c("p", [
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_2_li_1")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_2_li_2")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_2_li_3")) + " ")
            ]),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLogin,
                    expression: "!isLogin"
                  }
                ],
                attrs: { to: "/join" }
              },
              [
                _c("button", [
                  _vm._v(" Get started "),
                  _c("i", { staticClass: "fas fa-long-arrow-alt-right" })
                ])
              ]
            )
          ],
          1
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/main_image7.png"),
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "section2Wrap reverse" }, [
        _c(
          "div",
          { staticClass: "section2Text" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("product_title_3")))]),
            _c("p", { staticClass: "section2_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("product_context_3")) + " "),
              _c("br")
            ]),
            _c("p", [
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_3_li_1")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_3_li_2")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_3_li_3")) + " ")
            ]),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLogin,
                    expression: "!isLogin"
                  }
                ],
                attrs: { to: "/join" }
              },
              [
                _c("button", [
                  _vm._v(" Get started "),
                  _c("i", { staticClass: "fas fa-long-arrow-alt-right" })
                ])
              ]
            )
          ],
          1
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/main_image8.png"),
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "section2Wrap" }, [
        _c(
          "div",
          { staticClass: "section2Text" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("product_title_4")))]),
            _c("p", { staticClass: "section2_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("product_context_4")) + " "),
              _c("br")
            ]),
            _c("p", [
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_4_li_1")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_4_li_2")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_4_li_3")) + " ")
            ]),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLogin,
                    expression: "!isLogin"
                  }
                ],
                attrs: { to: "/join" }
              },
              [
                _c("button", [
                  _vm._v(" Get started "),
                  _c("i", { staticClass: "fas fa-long-arrow-alt-right" })
                ])
              ]
            )
          ],
          1
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/main_image6.png"),
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "section2Wrap reverse" }, [
        _c(
          "div",
          { staticClass: "section2Text" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("product_title_5")))]),
            _c("p", { staticClass: "section2_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("product_context_5")) + " "),
              _c("br")
            ]),
            _c("p", [
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_5_li_1")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_5_li_2")) + " "),
              _c("br"),
              _c("span", { staticClass: "material-icons" }, [
                _vm._v(" check ")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("product_context_5_li_3")) + " ")
            ]),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLogin,
                    expression: "!isLogin"
                  }
                ],
                attrs: { to: "/join" }
              },
              [
                _c("button", [
                  _vm._v(" Get started "),
                  _c("i", { staticClass: "fas fa-long-arrow-alt-right" })
                ])
              ]
            )
          ],
          1
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/main_image9.png"),
            alt: ""
          }
        })
      ])
    ]),
    _c("div", { staticClass: "mainSection3" }, [
      _c("div", { staticClass: "section3Wrap" }, [
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/main_image2.png"),
              alt: ""
            }
          }),
          _c("h2", [_vm._v(_vm._s(_vm.$t("product_context_6_1")))])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/main_image3.png"),
              alt: ""
            }
          }),
          _c("h2", [_vm._v(_vm._s(_vm.$t("product_context_6_2")))])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/main_image5.png"),
              alt: ""
            }
          }),
          _c("h2", [_vm._v(_vm._s(_vm.$t("product_context_6_3")))])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/main_image4.png"),
              alt: ""
            }
          }),
          _c("h2", [_vm._v(_vm._s(_vm.$t("product_context_6_4")))])
        ])
      ]),
      _c("div", { staticClass: "section3Text" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("product_title_6")))]),
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("product_subtitle_6")) + " ")]),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.handleMove("more")
              }
            }
          },
          [_vm._v("More")]
        )
      ])
    ]),
    _c("div", { staticClass: "mainSection4" }, [
      _c("div", { staticClass: "section4Wrap" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("demo_request")))]),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.handleMove("demo")
              }
            }
          },
          [_vm._v("Watch Demo")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }